import React from 'react'
import './index.css'

const ContactForm = () => {
  return (
    <>
    
    <div className="contact-form">
          <h2>
            Contact <span>Form</span>
          </h2>
          <form>
            <div className="form-main-div">
              <div className="left-column">
               
                  <input className="phone-input" type="text" name="phone" placeholder="Phone number" />
                  <input
                    type="email"
                    name="email"
                    placeholder="E-mail address"
                    className="phone-input"
                  />
               
               
                  <input className="name-input" type="text" name="name" placeholder="Name" />
                  <input className="surname-input" type="text" name="surname" placeholder="Surname" />
              
              </div>
              <div className="right-column">
                <textarea name="message" placeholder="Message"></textarea>
              </div>
              <div className="submit-button">
                <button type="submit">Send</button>
              </div>
            </div>
          </form>
        </div>
    
    
    </>
  )
}

export default ContactForm