import PropTypes from "prop-types";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { IoMdClose } from "react-icons/io";
import { getClosest, getSiblings, slideToggle, slideUp } from "../../utils";
import "./index.css";
import LanguageSelect from "../language-select";

const HamburgerMenu = ({ show, onClose }) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".offcanvas-menu")) {
        onClose();
      }
    };

    if (show) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show, onClose]);



  const onClickHandler = (e) => {
    const target = e.currentTarget;
    const parentEl = target.parentElement;
    if (
      parentEl?.classList.contains("mobile-menu-expand") ||
      target.classList.contains("mobile-menu-expand")
    ) {
      const element = target.classList.contains("icon") ? parentEl : target;
      const parent = getClosest(element, "li");
      const childNodes = parent.childNodes;
      const parentSiblings = getSiblings(parent);
      parentSiblings.forEach((sibling) => {
        const sibChildNodes = sibling.childNodes;
        sibChildNodes.forEach((child) => {
          if (child.nodeName === "UL") {
            slideUp(child, 1000);
          }
        });
      });
      childNodes.forEach((child) => {
        if (child.nodeName === "UL") {
          slideToggle(child, 1000);
        }
      });
    }
  };

  return (
    <div className={`offcanvas-menu ${show ? "open" : ""}`}>
      <div className="close-btn">
        <button className="menu-close" onClick={onClose}>
          <IoMdClose />
        </button>
      </div>
      <div className="offcanvas-wrapper">
        <div className="primary-menu">
          <ul>
            <li>
              <NavLink exact to="/" activeClassName="active">
                <img
                  alt="ok_icon"
                  className="arrow-hover"
                  src="/assets/svg/ok.svg"
                />
                Home Page
              </NavLink>
            </li>
            <li>
              <NavLink to="/about_us" activeClassName="active">
                <img
                  alt="ok_icon"
                  className="arrow-hover"
                  src="/assets/svg/ok.svg"
                />
                About Us
              </NavLink>
            </li>
            <li>
              <NavLink to="/our_certificates" activeClassName="active">
                <img
                  alt="ok_icon"
                  className="arrow-hover"
                  src="/assets/svg/ok.svg"
                />
                Our Certificates
              </NavLink>
            </li>
            <li>
              <NavLink to="/sustainability_vision" activeClassName="active">
                <img
                  alt="ok_icon"
                  className="arrow-hover"
                  src="/assets/svg/ok.svg"
                />
                Sustainability Vision
              </NavLink>
            </li>
            <li>
              <NavLink to="/mining_tunneling" activeClassName="active">
                <img
                  alt="ok_icon"
                  className="arrow-hover"
                  src="/assets/svg/ok.svg"
                />
                Mining 4.0 & Tunneling 4.0
              </NavLink>
            </li>
            <li>
              <NavLink to="/applications" activeClassName="active">
                <img
                  alt="ok_icon"
                  className="arrow-hover"
                  src="/assets/svg/ok.svg"
                />
                Applications
              </NavLink>
            </li>
            <li>
              <NavLink to="/rd_and_laboratory_studies" activeClassName="active">
                <img
                  alt="ok_icon"
                  className="arrow-hover"
                  src="/assets/svg/ok.svg"
                />
                R&D and Laboratory Studies
              </NavLink>
            </li>
            <li>
              <NavLink to="/communication" activeClassName="active">
                <img
                  alt="ok_icon"
                  className="arrow-hover"
                  src="/assets/svg/ok.svg"
                />
                Communication
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="secondary-menu">
         <LanguageSelect/>
        </div>
      </div>
    </div>
  );
};

HamburgerMenu.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default HamburgerMenu;
