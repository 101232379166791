import React from "react";
import "./index.css";
import Logo from "../../components/logo";
import Links from "../../components/footer/links";
import FooterBottom from "../../components/footer/footerBottom";
import { FaInstagram, FaLinkedin, FaXTwitter, FaYoutube, FaFacebookF } from "react-icons/fa6";
import LanguageSelect from "../../components/language-select";


const Footer = () => {


  return (
    <div className="footer-okdtm section ">
      <div className="container-fluid">
        <div className="footer-info-box d-flex justify-content-between">
          <div className="footer-logo-box">
            <Logo image={`/assets/svg/okdtm_logo2-01.svg`} />/
            <div className="social-box">
            <FaInstagram />
            <FaLinkedin />
            <FaXTwitter />
            <FaYoutube />
            <FaFacebookF />
            </div>
          </div>
          <div className="footer-pages-box">
           <Links selectedMenu="footerPagesMenu"/>
          </div>
          <div className="footer-applications-box">
            <Links  selectedMenu="footerApplicationsMenu"/>/
          </div>
          <div className="footer-communication-box">
          <Links  selectedMenu="footerCommunicationMenu"/>/
          <LanguageSelect/>
          </div>
        </div>

      <FooterBottom/>
      </div>
    </div>
  );
};

export default Footer;
