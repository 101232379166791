import React from "react";
import Layout from "../../layouts/layout";
import Banner from "../../components/banner";
import SustainabilityVisionDesc from "../../components/sustainabilityVisionDesc";


const Vision = () => {
  return (
    <>
      <Layout>

      <Banner title="Sustainability" titleBlue="Vision" imageUrl="" imageBool={false} />


<SustainabilityVisionDesc/>



      </Layout>
    </>
  );
};

export default Vision;
