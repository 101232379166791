import React from 'react'
import Layout from '../../../layouts/layout'
import DetailsPage from '../../../components/detailsPage'

const FireSuppressionAndInsulationPreventingAirAndGasLeakage = () => {
  return (
    <>    <Layout>
    
    <DetailsPage
     title="Fire Suppression And Insulation Preventing Air & Gas Leakage"
     titleBlue=""
     title2=""
     titleBlue2=""
     description1="NAROFLEKS™️ Phenolic Foam offers a reliable solution for fire suppression, insulation, and preventing air and gas leakage in underground coal mines. Fire-resistant properties, excellent insulation capabilities, and ability to create airtight seals make NAROFLEKS™️ an essential component in enhancing mine safety and efficiency."
     description2=""
     description3=""
     description4=""
     imageUrl="/assets/images/detailsPage/tunnel_img_world.png"
     tradeMark={false}
     imageBool={true}
    />
    
    </Layout>
    </>

  )
}

export default FireSuppressionAndInsulationPreventingAirAndGasLeakage