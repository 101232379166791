import React from "react";
import Layout from "../../layouts/layout";
import Banner from "../../components/banner";
import "./index.css";
import ContactForm from "../../components/contact-form";
import AddressAndMap from "../../components/addressAndMap";

const Communication = () => {
  return (
    <>
      <Layout>
        <Banner
          title="Communication"
          titleBlue=""
          imageUrl=""
          imageBool={false}
        />
       
<ContactForm />
<AddressAndMap />


      </Layout>
    </>
  );
};

export default Communication;
