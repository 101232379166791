import React, { useEffect, useState } from "react";
import "./index.css";
import Data from "../../data/home/home.json";

const IconBox = () => {
  const IconData = Data.iconBoxIcons;

  return (
    <>
      <div className="icon-box">
        {IconData.map((icon, index) => (
          <div className="main-border-div" key={index}>
            <div className="main-border">
              <div className="border-container">
                <div className="border-top-left" />
                <div className="border-bottom-right" />
                <div className="content">
                  <img
                    className="icon-icon"
                    src={icon.icon_url}
                    alt={icon.alt}
                  />
                </div>
              </div>
            </div>
            <div className="icon-box-title ">
              <p className="icon-title col-1">{icon.id}</p>
              <p className="icon-title col-10">{icon.title}</p>
              <p className="icon-title col-1"></p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default IconBox;
