import React from 'react'
import Layout from '../../../layouts/layout'
import DetailsPage from '../../../components/detailsPage'

const HardRockMining = () => {
  return (
    <>    <Layout>
    
    <DetailsPage
     title="Hard Rock"
     titleBlue="Mining"
     title2=""
     titleBlue2=""
     description1="Chemical injections are a vital technique in underground hard rock mining, addressing critical issues such as ground stability, water control, dust suppression, and gas management. When implemented effectively, they significantly enhance safety, operational efficiency, and environmental protection, making them an indispensable tool in modern mining operations."
     description2=""
     description3=""
     description4=""
     imageUrl="/assets/images/detailsPage/hardrock_img.png"
     tradeMark={false}
     imageBool={true}
    />
    
    </Layout>
    </>
  )
}

export default HardRockMining