import React from 'react'
import Layout from '../../../layouts/layout'
import DetailsPage from '../../../components/detailsPage'


const CoalMining = () => {
  return (
    <>
    <Layout>
    
    <DetailsPage
     title="Coal"
     titleBlue="Mining"
     title2=""
     titleBlue2=""
     description1="Underground coal mining requires careful attention to safety. Chemical injection and foam applications are vital tools for improving worker safety and operational efficiency in this industry. With the proper techniques, underground coal mining can be made safer and more sustainable."
     description2=""
     description3=""
     description4=""
     imageUrl="/assets/images/detailsPage/coal_img.png"
     tradeMark={false}
     imageBool={true}
    />
    
    </Layout>
    
    
    </>
  )
}

export default CoalMining