import React from "react";
import Layout from "../../layouts/layout";
import Banner from "../../components/banner";
import MiningDesc from "../../components/miningDesc";

const MiningTunneling = () => {
  return (
    <>
      <Layout>
        <Banner
          title="Mining"
          titleBlue="4.0"
          title2="Tunneling"
          titleBlue2="4.0"
          imageUrl="/assets/images/4.0.png"
          tradeMark={true}
          imageBool={true}
        />

      <MiningDesc/>


      </Layout>
    </>
  );
};

export default MiningTunneling;
