import React from 'react'
import './index.css'

const Slogan = () => {
  return (
    <>
    <div className="container-fluid container-slogan">
  <img src="/assets/svg/logo_vector.svg" alt="Mascot" className="mascot" />
  <div className="text">Ready to be <span>your local producer</span></div>
</div>

    
    </>
  )
}

export default Slogan