import React from "react";
import Layout from "../../layouts/layout";
import Banner from "../../components/banner";
import Presentation from "../../components/presentation";
import FrameAboutUs from "../../components/frame-about-us";
import Presentation2 from "../../components/presentation2";

const About = () => {
  return (
    <>
      <Layout>
      
        <Banner title="About Us" imageUrl="/assets/images/about_us.png" imageBool={true} />

        <div className="about-page">
          <div className="container">
            <Presentation2 title="OK Foreign Trade & Mining" title2="Forming the Underground" description="Welcome to OK Foreign Trade & Mining, a distinguished leader in the production of cutting-edge injection chemicals tailored to meet your specific industry requirements. With our prominent trademarks, Mining 4.0TM and Tunnelling 4.0TM, we stand at the forefront of innovation, delivering solutions that redefine standards in underground mining and tunneling. With a legacy spanning over three decades, our company has solidified its position as a trusted industry player, distinguishing itself among a select group of manufacturers globally. Operating exclusively in the underground mining and tunneling sector, we take pride in our expertise and commitment to providing optimal solutions. Our specialization lies in offering efficient and cost-effective remedies to a myriad of challenges. We excel in filling ceiling voids with our precision-engineered materials, reinforcing intersections in coal and stone galleries, addressing broken and cracked areas, isolating old working spaces, constructing both temporary and permanent dams, and preventing the flow of running materials. At OKFTM, we are driven by a relentless pursuit of excellence and a dedication to meeting the unique demands of our clients. Our injection chemicals, backed by the Mining 4.0TM and Tunnelling 4.0TM trademarks, symbolize not only our commitment to innovation but also our unwavering dedication to quality and performance. Partner with us, and experience the synergy of cutting-edge technology, industry expertise, and a commitment to sustainable solutions."/>

            <FrameAboutUs
              imageUrl="/assets/svg/logo_vector.svg"
              imageAlt="OkDtm Logo"
              reverse={true}
              titleWhite="Our Logo"
              titleBlue="A Canary’s Vigilance for Safety"
              description="Our logo features the vigilant canary—an enduring symbol of safety in coal mines. Historically, miners relied on these birds as early indicators of hazardous conditions, their sensitivity to gasses serving as a warning. Likewise, our injection chemicals embody this watchful spirit, designed as a risk mitigating technology, ensuring the well-being of those working in challenging environments. The canary in our logo signifies our unwavering commitment to proactive safety measures, echoing the past while advancing protection for the future."
            />

            <Presentation />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default About;
