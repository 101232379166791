import React from "react";
import Layout from "../../layouts/layout";
import Banner from "../../components/banner";
import Carousel from "../../components/carousel";
import CertData from "../../data/certificates/certificates.json";

const Certificates = () => {
  return (
    <>
      <Layout>

      <Banner title="Our" titleBlue="Certificates" imageUrl="" imageBool={false} />
      <Carousel data={CertData.ourCertificates} page="certificates"/>



      </Layout>
    </>
  );
};

export default Certificates;







