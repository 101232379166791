import React from "react";
import "./index.css";

function Banner({ title, titleBlue, title2, titleBlue2, imageUrl, tradeMark, imageBool }) {
  return (
    <>
      {imageBool ? (
        <div className="container-fluid banner">
          <div className="row first_row">
            <div className="col-12">
              <h1>{title}</h1>
              {titleBlue ? (
                <>
                  <h1 style={{ color: "#3B82F6", marginLeft: "0.75rem" }}>
                    {titleBlue}
                    {tradeMark ? (
                      <span className="trademark color-white">TM</span>
                    ) : null}
                  </h1>
                </>
              ) : null}
              {title2 ? <h1 style={{ marginLeft: "0.75rem" }}>{title2}</h1> : null}
              {titleBlue2 ? (
                <>
                  <h1 style={{ color: "#3B82F6", marginLeft: "0.75rem" }}>
                    {titleBlue2}
                    {tradeMark ? (
                      <span className="trademark color-white">TM</span>
                    ) : null}
                  </h1>
                </>
              ) : null}
            </div>
          </div>
          <div className="row second_row">
            <div className="col-12">
              <img src={imageUrl} alt="about_us_slider" />
            </div>
          </div>
        </div>
      ) : (
        <div className="container-fluid banner not-image">
          <div className="row first_row">
            <div className="col-12">
              <h1>{title}</h1>
              {titleBlue ? (
                <>
                  <h1 style={{ color: "#3B82F6", marginLeft: "0.75rem" }}>
                    {titleBlue}
                    {tradeMark ? (
                      <span className="trademark color-white">TM</span>
                    ) : null}
                  </h1>
                </>
              ) : null}
              {title2 ? <h1 style={{ marginLeft: "0.75rem" }}>{title2}</h1> : null}
              {titleBlue2 ? (
                <>
                  <h1 style={{ color: "#3B82F6", marginLeft: "0.75rem" }}>
                    {titleBlue2}
                    {tradeMark ? (
                      <span className="trademark color-white">TM</span>
                    ) : null}
                  </h1>
                </>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Banner;
