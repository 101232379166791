import React from "react";

const Presentation = ({title, title2, description}) => {
  return (
    <>
     <div className="about-page">
          <div className="container">
     <div className="row">
          <div className="col-12">
          {title ? (
        <div className="about-title">
          <h1>{title}</h1>
        </div>
      ) : null}
{title2 ? (
        <div className="about-title_blue">
          <h3>{title2}</h3>
        </div>
      ) : null}



      {description ? (
        <div className="about-content">
          <p>{description}</p>
        </div>
      ) : null}




            
         
        </div>
      </div>
      </div>
      </div>
    </>
  );
};

export default Presentation;
