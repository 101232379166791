import React from "react";
import Layout from "../../layouts/layout";
import Banner from "../../components/banner";
import Presentation2 from "../../components/presentation2";
import Carousel from "../../components/carousel";
import RdData from "../../data/rd_laboratory/rd_laboratory.json";




const RdLaboratory = () => {
  return (
    <>
      <Layout>

      <Banner title="R&D and" titleBlue="Laboratory Studies" imageUrl="/assets/images/randd.png" imageBool={true} />
      <Presentation2 title2="Our R&D Laboratory" description="Our R&D laboratory works to develop innovative solutions and high-performance products. Our expert team is continuously engaged in research and development activities to address the challenges faced in underground mining and tunnel construction."/>
      <Carousel data={RdData.iconBoxIcons} />



      </Layout>
    </>
  );
};

export default RdLaboratory;
